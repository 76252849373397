import { useEffect, useState } from 'react';
import './index.css'
function App() {
  const [frame, setframe] = useState('')

  useEffect(() => {
      getFrame()
  }, [])

  const getFrame = () => {
      fetch("https://udokaokoye.com/Portfolio%20Backend/bbnlive.php", {
          method: "GET"
      }).then((res) => res.json()).then((data) => {
          // console.log(data)
          setframe(data.frame)
      })
  }
  return (
    <div className="Container">

      <h1>Welcome To Big Brother Naija 2023 (All-Star) S8 Live Stream</h1>

            <div className="liveContainer">


            {frame ? (
                <div
                style={{width: "100%", height: "100%"}}
                dangerouslySetInnerHTML={{
                  __html: frame,
                }}
                className="news_body_text"
              />
            ) : (<h1>Loading...</h1>)}

            </div>

            {/* <p className='broghttoyou'><i>This stream is brought to you by udoka okoye</i></p> */}

            <div className="credits">
                {/* <p>Please like and follow <a href="https://www.facebook.com/UdokaDev" target="_blank">Udoka Okoye</a> on facebook</p> */}
                {/* <p>Please subscribe to my channel on Youtube - <a href="https://www.youtube.com/channel/UCWWUb0JP0l8vME6x0BEVJFA" target="_blank">Udoka Okoye</a></p> */}
            </div>
    </div>
  );
}

export default App;
